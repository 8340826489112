.weight-predict-chart__subtitle {
  font-weight: 500;
  font-size: 1rem;
  max-width: 83%;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.33;
  text-align: center;
  color: var(--text-color-secondary);
  margin-bottom: 1.1rem
}

.weight-predict-chart__title {
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.15;
  text-align: center;
  margin-bottom: 1rem
}

.weight-predict-chart__title_large {
  font-size: 2rem;
  font-weight: 900
}

.weight-predict-chart__title_large span {
  color: var(--text-color-primary)
}

.weight-predict-chart__title_large .weight-predict-chart__title-content {
  border: none;
  max-width: none
}

.weight-predict-chart__title-content {
  display: inline-block;
  max-width: 250px;
  border-bottom: 1px solid currentColor
}

.weight-predict-chart__title-content span {
  display: inline-block
}

.weight-predict-chart__paywall-subtitle {
  margin-bottom: .5rem;
  font-weight: 700;
  font-size: 1.1rem;
  text-align: center
}

.weight-predict-chart__chart-wrapper {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  /* padding: 1rem 1rem 2rem; */
  margin-bottom: .5rem;
  /* max-width: 420px; */
  min-height: 12rem
}

.weight-predict-chart__chart {
  color: var(--text-color-secondary);
  position: relative;
  justify-content: center;
  height: 9rem;
  width: 100%;
  border-left: 1px solid #e2e3ee;
  border-right: 1px solid #e2e3ee
}

.weight-predict-chart__note {
  font-size: .75rem;
  line-height: 1.25;
  text-align: center;
  color: var(--text-color-secondary)
}

.weight-predict-chart__event-description {
  font-size: .9375rem;
  line-height: 1.53;
  text-align: center;
  color: #666260;
  margin-bottom: .5rem
}

.weight-predict-chart__arrow-container {
  position: absolute;
  z-index: 1;
  left: 30%;
  padding-top: .625rem;
  opacity: 0;
  transform: translateY(-50%);
  transition: opacity .3s ease-in-out,transform .3s ease-in-out
}

.weight-predict-chart__line {
  position: relative;
  left: calc(50% - 1px);
  bottom: -9px
}

.weight-predict-chart__line.minify-arrow {
  transform: scale(.8)
}

.weight-predict-chart__chart-event {
  text-align: center;
  position: absolute;
  top: 2px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  font-weight: 700;
  font-size: .75rem;
  white-space: nowrap
}

.weight-predict-chart__chart-event.minify-arrow {
  top: 5px
}

.weight-predict-chart__chart-event span {
  text-transform: capitalize
}

.weight-predict-chart__static-arrow {
  opacity: 0;
  position: absolute;
  right: 5px;
  top: 0;
  display: flex;
  align-items: center;
  transform: translateX(-50%);
  transform-origin: center top;
  transition: opacity .3s ease-in-out,transform .3s ease-in-out
}

.weight-predict-chart__static-arrow .weight-predict-chart__chart-event {
  position: static;
  transform: none;
  margin-right: .25rem
}

.weight-predict-chart__goal-label {
  transition: opacity .3s ease-in-out,transform .3s ease-in-out;
  position: absolute;
  background-color: #56A97A;
  padding: .25rem;
  border-radius: .25rem;
  transform: translateX(-50%);
  box-shadow: 0 4px 10px rgba(0,0,0,.1);
  min-width: 3.75rem;
  transform-origin: center
}

.weight-predict-chart__goal-label-chevron {
  padding-top: .5rem;
  padding-bottom: .5rem;
  position: absolute;
  border: .5rem solid transparent;
  border-top-color: #56A97A;
  bottom: -1.875rem;
  left: 50%;
  margin-left: -.5rem
}

.weight-predict-chart__goal-label-text {
  color: #fff;
  text-align: center;
  font-size: .8125rem;
  line-height: 1.2;
  font-weight: 700
}

.weight-predict-chart__maintain-weight {
  position: absolute;
  text-align: center;
  /* right: 1rem; */
  bottom: 1rem;
  font-weight: 700;
  transition: opacity .3s,transform .3s;
  transform-origin: center
}

.weight-predict-chart__grid-item {
  position: absolute;
  top: 0;
  width: 1px;
  height: 100%;
  background: #e2e3ee
}

.weight-predict-chart__grid-item.weight-predict-chart__grid-item-1 {
  left: 25%
}

.weight-predict-chart__grid-item.weight-predict-chart__grid-item-2 {
  left: 50%
}

.weight-predict-chart__grid-item.weight-predict-chart__grid-item-3 {
  left: 75%
}

.weight-predict-chart__text-value {
  font-size: .8125rem
}

.weight-predict-chart__top-value {
  position: absolute;
  top: 0;
  left: .5rem
}

.weight-predict-chart__bottom-left-value {
  position: absolute;
  bottom: -1.5rem;
  left: 0;
  text-transform: uppercase
}

.weight-predict-chart__bottom-right-value {
  position: absolute;
  bottom: -1.5rem;
  right: 0;
  text-transform: uppercase
}

.weight-predict-chart__svg-wrapper {
  position: absolute;
  top: 0;
  bottom: -.5rem;
  left: -.75rem;
  right: 0
}

.weight-predict-chart__svg-chart {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%
}

.weight-predict-chart__chevron {
  display: block
}

.weight-predict-chart__chevron.minify-arrow {
  transform: scale(.8)
}

.slideDate-enter {
  transform: translateY(-12px);
  opacity: 1
}

.slideDate-enter-active,.slideDate-leave-active {
  transition: transform .2s,opacity .2s
}

.slideDate-enter-to {
  transform: translateY(0);
  opacity: 1
}

.slideDate-leave {
  top: 0;
  opacity: 1
}

.slideDate-leave-to {
  transform: translateY(0);
  opacity: 0
}
