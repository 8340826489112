@import url("https://fonts.googleapis.com/css2?family=Courgette&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "react-toastify/dist/ReactToastify.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
  font-size: 18px;
  letter-spacing: -0.035em;
}

html {
  scroll-behavior: smooth;
}

@layer utilities {
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .scrollbar::-webkit-scrollbar {
    width: 8px;
  }

  .scrollbar::-webkit-scrollbar-track {
    background: transparent;
  }

  .scrollbar::-webkit-scrollbar-thumb {
    background-color: #858d96;
    border-radius: 0.25rem !important;
    border: 2px solid transparent;
  }

  .scrollbar {
    scrollbar-width: thin;
    scrollbar-color: #858d96 transparent;
  }
}

@layer components {
  .app-container {
    @apply font-Montserrat w-full min-h-screen py-5;
  }

  .wrapper {
    @apply w-full max-w-[580px] mx-auto px-5;
  }

  .notify {
    background-color: rgba(243, 170, 81, 0.1);
    border-color: rgba(243, 170, 81, 0.1);
  }

  .sticky-bottom {
    @apply sticky bottom-0 right-0 w-full mt-2;
  }
}

@media screen and (max-width: 768px) {
  .mobileGradient {
    background: linear-gradient(
      rgba(255, 255, 255, 0) 0%,
      rgb(255, 255, 255) 100%
    );
  }
}

.check_box_link_continue {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 65px;
  padding: 0 17px;
  z-index: 1;
  cursor: pointer;
  border-radius: 6px;
  margin: 20px 0 0;
  background-image: linear-gradient(90deg, #95d833, #71b114);
  border: #95d833 3px solid;
}
.check_box_link_continue:hover {
  background-image: linear-gradient(
    to right,
    #38d7b7,
    #00c7c8,
    #00b6d4,
    #00a2d6,
    #268dce
  );
  border: 3px solid #38d7b7;
}

.check_box_link_icon {
  width: 35px;
  height: 35px;
  background: url("https://dev.myketoslim.com/images/continue_icon.png")
    no-repeat;
  background-size: 100%;
}
.check_box_link_icon {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 17px;
  margin: auto;
  width: 33px;
  height: 33px;
  fill: hsla(0, 0%, 100%, 0.7);
  transition: fill 0.35s ease;
}

.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #14b8a6;
  width: 35px;
  height: 35px;
  margin: 0 auto;
  -webkit-animation: spin 1.5s linear infinite; /* Safari */
  animation: spin 1.5s linear infinite;
}
/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* default container fix */

@media (min-width: 280px) {
  .container {
    max-width: 100%;
  }
}

@media (min-width: 560px) {
  .container {
    max-width: 100%;
  }
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

@media (min-width: 1725px) {
  .container {
    max-width: 1536px;
  }
}
