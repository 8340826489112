
:root {
  --bg1: rgb(254, 251, 245);
  --bg2: rgb(249, 245, 239);
  --bg3: rgb(230, 237, 233);
  
};

.estimations {
  position: relative;
  font-size: 11px;
  /* border-block-start: 1px dashed #ccc; */
}

.section-wrapper {
  max-width: 1060px;
  margin: 0 auto;
}

.estimations::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  z-index: 0;
  height: 2px;
  width: 140%;
  transform: translateX(-15%);
  border-top: 1px dashed #ccc;
}

.estimations2 {
  position: relative;
  font-size: 11px;
  /* border-block-start: 1px dashed #ccc; */
}

.estimations2::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  z-index: 0;
  height: 2px;
  width: 150%;
  transform: translateX(-15%);
  border-top: 1px dashed #ccc;
}

.estimations span, .estimations2 span {
  position: relative;
  font-size: 11px;
  z-index: 1;
}

.section-bg1::before {
  content: "";
  background-color: #F0FFFD;
  height: 70%;
  width: 99.7%;
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  z-index: 1;
  border-radius: 500px 500px 300px 600px;
}

.section-bg1::after {
  content: "";
  background: rgba(255, 88, 75, 0.17);
  height: 60%;
  width: 100%;
  position: absolute;
  display: block;
  z-index: 0;
  top: 150px;
  left: 180px;
  border-radius: 200px 500px 800px 300px;
  transform: rotate(6deg);
}

.section-bg1>*, .section-bg2>* {
  z-index: 5;
  position: relative;
}

.gradient {
  background: linear-gradient(90deg, #13BBA0 0%, #FF584B 100%);
  position: relative;
}

.section-bg2 {
  
}

.section-bg2::before {
  content: "";
  /* background-color: #F0FFFD;
  height: 85%;
  width: 80%;
  position: absolute;
  left: 10%;
  bottom:0;
  display: block;
  z-index: 1;
  border-radius: 900px 300px 800px 200px; */
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  background-image: url('./assets/bg2.png');
  background-position: center;
  background-repeat: no-repeat;
}

.section-bg2::after {
  content: "";
  /* background: rgba(255, 88, 75, 0.17);
  height: 78%;
  width: 75%;
  position: absolute;
  display: block;
  z-index: 0;
  bottom: 0;
  right: 10%;
  transform: rotate(12deg);
  border-radius: 600px 500px 800px 300px; */
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  background-image: url('./assets/bg1.png');
  background-position: bottom center;
  background-repeat: no-repeat;
}
